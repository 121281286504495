import type { MetaFunction } from "@remix-run/node"

import * as Icon from "@iyk/icons"

import { cva } from "@iyk/ui"
import { json, Link } from "@remix-run/react"
import { Header } from "../lib/ui/header.tsx"
import { setThemeTo } from "../lib/utils/classname.ts"

export const meta: MetaFunction = () => {
  return [{ title: "wwWorlds" }, { name: "description", content: "wwWorlds" }]
}

export const loader = async () => {
  return json({ theme: "dark" })
}

export default function Index() {
  return (
    <div className={classNameForIndex()}>
      <Header variant="fixed" />
      <div className="grow flex items-center justify-center">
        <Link to="https://iyk.app">
          <Icon.IykLogo className="size-14" />
        </Link>
      </div>
    </div>
  )
}

const classNameForIndex = cva(["h-dvh flex flex-col bg-background", setThemeTo("dark")])
